<template>
    <div>
        <Modal :options="{close: true}" @close="$emit('close')">
            <div class="title">Nuevo ticket</div>
            <div class="body">
                <div class="row form-group">
                    <label for="area" class="col-form-label col-sm-3">Área a levantar el ticket</label>
                    <div class="col-sm-9">
                        <select v-model="ticket.area_id" name="area" id="area" class="form-control">
                            <option value="na">Selecciona una área</option>
                            <option v-for="(area,index) in areas" :key="index" :value="area.id">{{ area.nombre }}</option>
                        </select>
                    </div>
                </div>
                <div class="row form-group">
                    <label for="prioridad" class="col-form-label col-sm-3">Prioridad</label>
                    <div class="col-sm-9">
                        <select v-model="ticket.prioridad" name="prioridad" id="prioridad" class="form-control">
                            <option value="na">Selecciona la prioridad</option>
                            <option value="baja">Baja</option>
                            <option value="media">Media</option>
                            <option value="alta">Alta</option>
                            <option value="urgente">Urgente</option>
                        </select>
                    </div>
                </div>
                <div class="row form-group">
                    <label for="requerimientos" class="col-form-label col-sm-3">Tipo de requerimiento</label>
                    <div class="col-sm-9">
                        <select v-model="ticket.requerimiento_id" name="requerimientos" id="requerimientos" class="form-control">
                            <option value="na">Selecciona el requerimiento</option>
                            <option v-for="req in requerimientos" :value="req.id">{{ req.nombre }}</option>
                        </select>
                    </div>
                </div>
                <template v-for="campo in campos">
                    <div v-if="campo.tipo == 'select'" class="row form-group">
                        <label :for="obtener_nombre_campo(campo)" class="col-form-label col-sm-3">{{ campo.nombre }}</label>
                        <div class="col-sm-9">
                            <select class="form-control" :name="obtener_nombre_campo(campo)" :id="obtener_nombre_campo(campo)">
                                <option v-for="opcion in obtener_campo_valor(campo)" :value="opcion.value">{{ opcion.text }}</option>
                            </select>
                        </div>
                    </div>
                    <div v-if="campo.tipo == 'text'" class="row form-group">
                        <label :for="obtener_nombre_campo(campo)" class="col-form-label col-sm-3">{{ campo.nombre }}</label>
                        <div class="col-sm-9"><input type="text" :name="obtener_nombre_campo(campo)" :id="obtener_nombre_campo(campo)" class="form-control" :placeholder="campo.valor"></div>
                    </div>
                    <div v-if="campo.tipo == 'email'" class="row form-group">
                        <label for="obtener_nombre_campo(campo)" class="col-form-label col-sm-3">{{ campo.nombre }}</label>
                        <div class="col-sm-9"><input type="email" :name="obtener_nombre_campo(campo)" :id="obtener_nombre_campo(campo)" class="form-control" :placeholder="campo.valor"></div>
                    </div>
                    <div v-if="campo.tipo == 'number'" class="row" form-group>
                        <label for="obtener_nombre_campo(campo)" class="col-form-label col-sm-3">{{ campo.nombre }}</label>
                        <div class="col-sm-9"><input type="number" :name="obtener_nombre_campo(campo)" :id="obtener_nombre_campo(campo)" class="form-control" :placeholder="campo.valor"></div>
                    </div>
                    <div v-if="campo.tipo == 'textarea'" class="row form-group">
                        <label :for="obtener_nombre_campo(campo)" class="col-form-label col-sm-3">{{ campo.nombre }}</label>
                        <div class="col-sm-9"><textarea :name="obtener_nombre_campo(campo)" :id="obtener_nombre_campo(campo)" cols="30" rows="10" class="form-control" :placeholder="obtener_campo_valor(campo)"></textarea></div>
                    </div>
                    <template v-if="campo.tipo == 'files'">
                    <div class="row form-group">
                        <label :for="obtener_nombre_campo(campo)" class="col-form-label col-sm-3">{{ campo.nombre }}</label>
                        <div class="col-sm-9 lista-archivos">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Nombre</th>
                                        <th>Tipo</th>
                                        <th>Peso</th>
                                        <th>&nbsp;</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-if="documentos.length == 0">
                                        <td colspan="4">No hay documentos</td>
                                    </tr>
                                    <template v-else>
                                    <tr v-for="(archivo,index) in documentos" :key="index">
                                        <td>{{ archivo.name }}</td>
                                        <td>{{ obtener_extension(archivo.name) }}</td>
                                        <td>{{ $helper.bytesConverted(archivo.size) }}</td>
                                        <td>
                                            <img src="https://static.bienparabien.com/constelacion/img/bin.png" alt="Elimitar incidencia" title="Elimitar incidencia" @click="eliminar_documento(index)" />
                                        </td>
                                    </tr>
                                    </template>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div v-if="documentos.length < campo.documentos_maximos" class="row form-group">
                        <div class="col-sm-9 offset-sm-3 text-right">
                            <button class="btn btn-info" @click="$refs.add_documento[0].click()">Agregar</button>
                            <input ref="add_documento" type="file" name="add_documento" id="add_documento" @change="agregar_documento">
                        </div>
                    </div>
                    </template>
                </template>
            </div>
            <div class="footer">
                <div class="row">
                    <div class="col-sm-12 text-right">
                        <button class="btn btn-primary mr-2" @click="crear_ticket">Crear ticket</button>
                        <button class="btn btn-danger" @click="$emit('close')">Cancelar</button>
                    </div>
                </div>
            </div>
        </Modal>

        <Modal v-if="modal_sin_evidencia" :options="{width: '40vw',type: 'danger'}">
            <div class="title">Ticket sin evidencia</div>
            <div class="body">
                <p class="text-center">Enviaras el ticket sin agregar ninguna evidencia.<br/>¿Deseas continuar?</p>
            </div>
            <div class="footer">
                <div class="row">
                    <div class="col-sm-12 text-right">
                        <button class="btn btn-primary mr-2">Crear ticket</button>
                        <button class="btn btn-danger" @click="modal_sin_evidencia=false">Cancelar</button>
                    </div>
                </div>
            </div>
        </Modal>
    </div>
</template>

<script>
import Modal from '@/components/Modal'

import apiTickets from '@/apps/constelacion/api/tickets'

export default {
    components: {
        Modal
    }
    ,data() {
        return {
            areas:[{nombre: 'It',codigo: 'it'},{nombre:'Marketing',codigo: 'marketing'}],
            confirmar_sin_evidencia: false,
            ejemplo_agregado: false,
            ticket: {
                area_id: 'na'
                ,requerimiento_id: 'na'
                ,prioridad: 'na'
                ,detalles: null
            },
            documentos: [],
            modal_sin_evidencia: false
        }
    }
    ,mounted() {
        this.obtener_areas();
    }
    ,methods: {
        async obtener_areas() {
            try {
                this.areas = (await apiTickets.listar_areas()).data;
            }catch(e) {
                this.$log.info('error',e);
                this.$helper.showAxiosError(e,'Error');
            }
        },
        obtener_campo_valor(campo) {
            switch(campo.valor_tipo) {
                case 'json':
                    return JSON.parse(campo.valor);
                default:
                    return campo.valor;
            }
        },
        obtener_nombre_campo(campo) {
            return 'requerimiento_campo_'+this.$helper.normalizar(campo.nombre);
        },
        agregar_documento() {
            if (this.$refs.add_documento[0].files.length > 0) {
                this.documentos.push(this.$refs.add_documento[0].files[0]);
                this.$refs.add_documento[0].value = null;
            }
        },
        obtener_extension(file) {
            return file.slice(file.indexOf('.')+1);
        },
        eliminar_documento(index) {
            let documentos = [];
            for(let i=0; i<this.documentos.length; i++) {
                if(i!=index)
                    documentos.push(this.documentos[i]);
            }

            this.documentos = documentos;
        },
        async crear_ticket() {
            try {
                let error = false;
                this.ticket.detalles = '';
                this.campos.forEach(campo => {
                    if (campo.tipo != 'files') {
                        let input = document.querySelector('#requerimiento_campo_'+this.$helper.normalizar(campo.nombre));

                        if (!input.value && campo.requerido == 1) {
                            this.$helper.showMessage('Error','El campo '+campo.nombre+' debe tener valor','error','alert');
                            error = true;
                        }

                        this.ticket.detalles += campo.nombre+': '+input.value+"\n\n";
                    }else {
                        if (this.documentos.length < parseInt(campo.documentos_requeridos)) {
                            let faltante = parseInt(campo.documentos_requeridos) - this.documentos.length;
                            this.$helper.showMessage('Error','Faltan '+faltante+' documento(s) a seleccionar','error','alert');
                            error = true;
                        }

                        this.ticket.documentos = this.documentos;
                    }
                });

                if (error)
                    return;

                this.$log.info('ticket: ',this.ticket);
                let ticket = new FormData();
                ticket.append('area_id', this.ticket.area_id);
                ticket.append('requerimiento_id', this.ticket.requerimiento_id);
                ticket.append('detalles', this.ticket.detalles);
                ticket.append('prioridad', this.ticket.prioridad);

                if (this.documentos.length > 0) {
                    let numDoc = 0;
                    this.documentos.forEach(doc => {
                        ticket.append('documentos['+numDoc+']', doc);
                        numDoc++;
                    });
                }

                let res = (await apiTickets.crear_ticket(ticket)).data;
                this.$log.info('res', res);

                this.$emit('close_update');
            }catch(e) {
                this.$log.info('error',e);
                this.$helper.showAxiosError(e,'Error');
            }
        }
    }
    ,computed: {
        requerimientos() {
            let requerimientos = [];

            this.ticket.requerimiento_id = 'na';

            this.areas.forEach(area => {
                if (area.id == this.ticket.area_id)
                    requerimientos = area.requerimientos;
            })

            return requerimientos;
        },
        campos() {
            let campos = [];

            this.areas.forEach(area => {
                if (area.id == this.ticket.area_id)
                    area.requerimientos.forEach(requerimiento => {
                        if (requerimiento.id == this.ticket.requerimiento_id) {
                            this.$log.info('requerimientos: ', requerimiento);
                            campos = requerimiento.campos;
                        }
                    })
            })

            return campos;
        }
    }
}
</script>

<style lang="scss" scoped>
    textarea {
        resize: none !important;
    }

    input[type="file"] {
        display: none;
    }

    .lista-archivos {
        max-height: 150px;
        overflow: hidden;
        overflow-y: auto;
        
        table {
            width: 100%;

            thead {
                
                tr {
                    background-color: #4C4C4C ;

                    th {
                        padding: 5px 10px;
                    }
                }
            }

            tbody {
                tr {
                    border-bottom: solid 1px #4C4C4C;

                    td {
                        padding: 5px 10px;

                        img {
                            width: 24px;
                        }
                    }

                    td:nth-child(4) {
                        text-align: center;
                    }
                }

                tr:last-child {
                    border: 0px;
                }
            }
        }
    }
</style>